import { defineStore } from 'pinia'
import { Organization } from '../database'


export const useMainStore = defineStore('main', () => {
  const pause = ref(false)
  const organizationId = useSessionStorage<string | null>('shairz-organization-id', null)

  const organization = computed(() => {
    if (!organizationId.value || pause.value) return null
    const organization = useRepo(Organization).withAllRecursive().find(organizationId.value)
    organization?.admins.sort((a, b) => a.name.localeCompare(b.name))
    organization?.plans.sort((a, b) => a.name.localeCompare(b.name))
    organization?.shareholders.sort((a, b) => a.type.localeCompare(b.type) || (a.category ?? '').localeCompare(b.category ?? '') || (a.fullName ?? '').localeCompare(b.fullName ?? ''))
    organization?.entities.sort((a, b) => a.name.localeCompare(b.name))
    organization?.securities.sort((a, b) => a.fullName.localeCompare(b.fullName))
    organization?.transactions.sort((a, b) => a.date.localeCompare(b.date))
    organization?.valuations.sort((a, b) => a.date.localeCompare(b.date))
    organization?.agregates.sort((a, b) => a.name.localeCompare(b.name))
    organization?.transactions.sort((a, b) => a.date.localeCompare(b.date))
    organization?.operations.sort((a, b) => a.name.localeCompare(b.name))
    return organization
  })

  const organizations = computed(() => {
    const organizations = useRepo(Organization).orderBy('name').get()
    return organizations
  })
  
  return { organizationId, organizations, organization, pause }
})
