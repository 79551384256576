import { Model } from 'pinia-orm'
import Organization from '~/database/models/Organization'
import Security from '~/database/models/Security'
import Transaction from '~/database/models/Transaction'

export default class Plan extends Model {
  static entity = 'plans'

  static fields() {
    return {
      id: this.string(null).notNullable(),
      name: this.string(null).notNullable(),
      maximum: this.number(null),
      expires_on: this.string(null),
      description: this.string(null),
      organization_id: this.string(null).notNullable(),
      security_id: this.string(null).notNullable(),
      organization: this.belongsTo(Organization, 'organization_id'),
      security: this.belongsTo(Security, 'security_id'),
      transactions: this.hasMany(Transaction, 'plan_id')
    }
  }

  declare id: string
  declare name: string
  declare maximum: number | null
  declare expires_on: string | null
  declare description: string | null
  declare organization_id: string
  declare security_id: string
  declare organization: Organization
  declare security: Security
  declare transactions: Transaction[]

  get distinctDates() {
    const distinctDates = this.transactions
      .filter((transaction, index, transactions) => transactions.findIndex(tr => tr.date === transaction.date) === index)
      .map(transaction => transaction.date)
      .sort()
    return distinctDates
  }

  shareholderAllocated(shareholder_id: string, date = new Date().toISOString().slice(0, 10)) {
    return this.shareholderVested(shareholder_id, date) + this.shareholderUnvested(shareholder_id, date)
  }

  shareholderVested(shareholder_id: string, date = new Date().toISOString().slice(0, 10)) {
    return this.transactions
      .filter(transaction => transaction.shareholder_id === shareholder_id)
      .filter(transaction => transaction.date <= date)
      .filter(transaction => !transaction.is_reserve)
      .filter(transaction => transaction.type !== 'grant' && transaction.type !== 'vesting')
      .reduce((total, transaction) => total + transaction.quantity, 0)
  }

  shareholderUnvested(shareholder_id: string, date = new Date().toISOString().slice(0, 10)) {
    return this.transactions
      .filter(transaction => transaction.shareholder_id === shareholder_id)
      .filter(transaction => transaction.date <= date)
      .filter(transaction => !transaction.is_reserve)
      .filter(transaction => transaction.type === 'grant' || transaction.type === 'vesting')
      .reduce((total, transaction) => total + transaction.quantity, 0)
  }

  shareholderReserved(shareholder_id: string, date = new Date().toISOString().slice(0, 10)) {
    return this.transactions
      .filter(transaction => transaction.shareholder_id === shareholder_id)
      .filter(transaction => transaction.date <= date)
      .filter(transaction => transaction.is_reserve)
      .filter(transaction => transaction.type !== 'grant' && transaction.type !== 'vesting')
      .reduce((total, transaction) => total + transaction.quantity, 0)
  }

  allocated(date = new Date().toISOString().slice(0, 10)) {
    return this.organization.shareholders.reduce((total, shareholder) => total + this.shareholderAllocated(shareholder.id, date), 0)
  }

  reserved(date = new Date().toISOString().slice(0, 10)) {
    return this.organization.shareholders.reduce((total, shareholder) => total + this.shareholderReserved(shareholder.id, date), 0)
  }

  unallocated(date = new Date().toISOString().slice(0, 10)) {
    if ((!this.expires_on || date <= this.expires_on) && (this.maximum)) {
      return this.maximum - this.allocated(date) - this.reserved(date)
    } else {
      return 0
    }
  }

  get unvestedEvents() {
    // All the grant events (+) and vesting events (-)
    return this.transactions
      .filter(({ type }) => type === 'grant' || type === 'vesting')
      .map(transaction => ({ ...transaction, event: 'unvested' }))
  }

  get vestedEvents() {
    // All the vesting events (-) and exercise events = issuance with vesting counterparted
    return this.transactions
      .filter(({ type, counterpart_of }) => type === 'vesting' || (type === 'issuance' && counterpart_of?.type === 'vesting'))
      .map(transaction => ({ ...transaction, event: 'vested', quantity: -transaction.quantity }))
  }

  get restrictedEvents() {
    // All the issuances (+) and transfers (-), outside of reserve
    return this.transactions
      .filter(({ type, is_reserve }) => (type === 'issuance' || type === 'transfer') && (!is_reserve))
      .map(transaction => ({ ...transaction, event: 'restricted' }))
  }

  get reserveEvents() {
    // All the issuances and transfers, only in reserve
    return this.transactions
      .filter(({ type, is_reserve }) => (type === 'issuance' || type === 'transfer') && (is_reserve))
      .map(transaction => ({ ...transaction, event: 'reserved' }))
  }

  get unallocatedEvents() {
    if (!this.maximum) {
      return []
    }
    const events = this.transactions
      .filter(({ type, quantity }) => type === 'grant' && quantity > 0)
      .map(transaction => ({ ...transaction, event: 'unallocated', quantity: -transaction.quantity, shareholder: { ...transaction.shareholder, fullName: 'No Beneficiary' } }))
    //@ts-ignore
    events.push({ type: 'authorization', date: '2000-01-01', shareholder: { fullName: 'No Beneficiary' }, quantity: this.maximum, event: 'unallocated' })
    return events
  }

  get elapsedEvents() {
    // Only the negative grants
    return this.transactions
      .filter(({ type, quantity }) => type === 'grant' && quantity < 0)
      .map(transaction => ({ ...transaction, event: 'elapsed', quantity: -transaction.quantity }))
  }

  get unrestrictedEvents() {
    // Find the transactions outside of plan that originated from the plan
    return this.organization.transactions.filter(transaction => (!transaction.plan_id) && (transaction.counterpart?.plan_id === this.id))
      .map(transaction => ({ ...transaction, event: 'unrestricted' }))
  }

  get events() {
    return [
      ...this.unvestedEvents,
      ...this.vestedEvents,
      ...this.restrictedEvents,
      ...this.reserveEvents,
      ...this.unallocatedEvents,
      ...this.elapsedEvents,
      ...this.unrestrictedEvents
    ]
  }
}
