import { Model } from 'pinia-orm'
import Organization from '~/database/models/Organization'
import Entity from '~/database/models/Entity'
import Security from '~/database/models/Security'

export default class Waterfall extends Model {
  static entity = 'waterfalls'

  static fields() {
    return {
      id: this.string(null).notNullable(),
      organization_id: this.string(null).notNullable(),
      entity_id: this.string(null),
      security_id: this.string(null),
      code: this.string(null),
      params: this.attr(null),
      organization: this.belongsTo(Organization, 'organization_id'),
      entity: this.belongsTo(Entity, 'entity_id'),
      security: this.belongsTo(Security, 'security_id')
    }
  }

  declare id: string
  declare organization_id: string
  declare entity_id: string | null
  declare security_id: string | null
  declare code: string | null
  declare params: object | null
  declare organization: Organization
  declare entity: Entity | null
  declare security: Security | null
}
