// This plugin ensures that the realtime channels are kept up to date
import type { Database } from '../supabase/types'
import { Organization, Agregate, Entity, Security, Operation, Shareholder, Transaction, Valuation, Plan, Waterfall } from '../database'
import { useMainStore } from '~/stores'
import { storeToRefs } from 'pinia'

export default defineNuxtPlugin(() => {
  const supabase = useSupabaseClient<Database>()
  const orgRepo = useRepo(Organization)
  const user = useSupabaseUser()
  const organizationsChannel = ref<ReturnType<typeof supabase["channel"]>>()
  const organizationChannel = ref<ReturnType<typeof supabase["channel"]>>()
  const store = useMainStore()
  const { organizationId } = storeToRefs(store)

  watch(user, () => {
    resetChannels()
  }
, {
    immediate: true // ensure we clean out all repos upon loading the app
  })

  watch(organizationId, (organization_id) => {
    deleteOrganizationChannel()
    if (organization_id) {
      createOrganizationChannel(organization_id)
    }
  })

  async function resetChannels() {
    await deleteOrganizationsChannel()
    if (user.value) {
      createOrganizationsChannel()
    }
    await deleteOrganizationChannel()
    if (store.organizationId) {
      createOrganizationChannel(store.organizationId)
    }
  }

  function createOrganizationsChannel() {
    organizationsChannel.value = supabase.channel('organizations').on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'organizations' },
      async payload => {
        const { eventType, new: _organization, old } = payload
        const org: any = _organization
        if (org.logoUrl) {
          URL.revokeObjectURL(org.logoUrl)
        }
        const { data, error } = await supabase.storage.from('media').download(`${org.id}/${org.logo}`)
        if (error) {
          org.logoUrl = null
        } else {
          org.logoUrl = URL.createObjectURL(data)
        }
        // Update Pinia ORM
        switch (eventType) {
          case 'INSERT':
            orgRepo.save(org)
            break
          case 'UPDATE':
            orgRepo.save(org)
            break
          case 'DELETE':
            orgRepo.destroy(old.id)
            break
        }
      })
    organizationsChannel.value.subscribe()
  }

  async function deleteOrganizationsChannel() {
    if (organizationsChannel.value) {
      await supabase.removeChannel(organizationsChannel.value)
    }
  }

  function createOrganizationChannel(organization_id: string) {
    const models = [Agregate, Entity, Security, Operation, Shareholder, Transaction, Valuation, Plan, Waterfall]
    organizationChannel.value = supabase.channel('organization')
    for (const model of models) {
      organizationChannel.value = organizationChannel.value.on(
        'postgres_changes',
        { event: '*', schema: 'public', table: model.entity, filter: `organization_id=eq.${organization_id}` },
        payload => {
          const { eventType, new: data, old } = payload
          //@ts-ignore
          const modelRepo = useRepo(model)
          switch (eventType) {
            case 'INSERT':
              modelRepo.save(data)
              break
            case 'UPDATE':
              modelRepo.save(data)
              break
            case 'DELETE':
              modelRepo.destroy(old.id)
              break
          }
        }
      )
    }
    organizationChannel.value.subscribe()
  }

  async function deleteOrganizationChannel() {
    if (organizationChannel.value) {
      await supabase.removeChannel(organizationChannel.value)
    }
  }
})

