import { Model } from 'pinia-orm'
import Organization from '~/database/models/Organization'
import Transaction from '~/database/models/Transaction'

export default class Operation extends Model {
  static entity = 'operations'

  static fields() {
    return {
      id: this.string(null).notNullable(),
      name: this.string(null).notNullable(),
      description: this.string(null),
      organization_id: this.string(null).notNullable(),
      organization: this.belongsTo(Organization, 'organization_id'),
      transactions: this.hasMany(Transaction, 'operation_id')
    }
  }

  declare id: string
  declare name: string
  declare description: string | null
  declare organization_id: string
  declare organization: Organization
  declare transactions: Transaction[]
}
