// This plugin ensures that the application is automatically redirecting pages upon user login and logout across multiple tabs.
// It also makes sure that the user's organizations are loaded and cleared upon User login and logout
import type { Database } from '../supabase/types'
import { Organization } from '../database'
import { useMainStore } from '~/stores'

export default defineNuxtPlugin(() => {
  const supabase = useSupabaseClient<Database>()
  const orgRepo = useRepo(Organization)
  const user = useSupabaseUser()
  const { $sentry } = useNuxtApp()
  const store = useMainStore()


  watch(user, async (newV, oldV) => {
    const { name } = useRoute()
    if (newV === null) {
      // Logout event received
      $sentry?.setUser(null)
      store.organizationId = null
      clearOrganizations()
      navigateTo('/login')
    } else {
      // Login event received (or refresh)
      $sentry?.setUser({ id: newV.id })
      if (!oldV) {
        // It is not a refresh so we load the organizations
        await loadOrganizations()
      }
      if (name === 'login') {
        // We only redirect to the organizations page if the user is in the login page
        navigateTo('/organizations')
      }
    }
  }
, {
    immediate: true // ensure we clean out all repos upon loading the app
  })

  async function loadOrganizations() {
    const { data: _organizations, error } = await supabase.from('organizations').select()
    if (error) {
      // JWTIssuedAtFuture captured here
      throw(error)
    }
    for (const _organization of _organizations) {
      const org: typeof _organization & { logoUrl?: string | null } = _organization
      if (org.logoUrl) {
        URL.revokeObjectURL(org.logoUrl)
      }
      const { data, error } = await supabase.storage.from('media').download(`${org.id}/${org.logo}`)
      if (error) {
        org.logoUrl = null
      } else {
        org.logoUrl = URL.createObjectURL(data)
      }
    }
    orgRepo.fresh(_organizations)
    if (_organizations.length === 1) {
      store.organizationId = _organizations[0].id
    }
  }

  function clearOrganizations() {
    orgRepo.flush()
  }

})

