// This plugin ensures that the active organization is automatically loaded and cleared when the selection changes
import type { Database } from '../supabase/types'
import { Agregate, Entity, Security, Operation, Shareholder, Transaction, Valuation, Plan, Waterfall, Admin } from '../database'
import { storeToRefs } from 'pinia'
import { useMainStore } from '~/stores'

export default defineNuxtPlugin(() => {
  const supabase = useSupabaseClient<Database>()
  const store = useMainStore()
  const { organizationId, pause } = storeToRefs(store)

  watch(organizationId, async (organization_id) => {
    pause.value = true
    if (organization_id) {
      await loadOrganizationTree(organization_id)
    } else {
      clearOrganizationTree()
    }
    pause.value = false
  }, {
    immediate: true // ensure we load the org repo upon loading the app
  })

  async function loadOrganizationTree(organization_id: string) {
    // Load tables
    const models = [Agregate, Entity, Security, Operation, Shareholder, Transaction, Valuation, Plan, Waterfall]
    await Promise.all(models.map(async model => {
      const rows = []
      let index = 0
      let count = 0
      do {
        const { data, error } = await supabase
          .from(model.entity)
          .select()
          .eq('organization_id', organization_id)
          .range(index, index + 999)
        if (error) {
          return showError(error)
        }
        rows.push(...data)
        count = data.length
        index += 1000
      } while (count === 1000)
      //@ts-ignore
      const modelRepo = useRepo(model)
      modelRepo.fresh(rows)
    }))

    // Load admins
    const { data } = await supabase.functions.invoke('utils', {
      headers: {
        'x-operation': 'listAdmins',
        'x-organization': organization_id
      }
    })
    const adminRepo = useRepo(Admin)
    adminRepo.fresh(data)
  }

  function clearOrganizationTree() {
    const models = [Agregate, Entity, Security, Operation, Shareholder, Transaction, Valuation, Plan, Waterfall]
    for (const model of models) {
      //@ts-ignore
      const modelRepo = useRepo(model)
      modelRepo.flush()
    }
  }

})

