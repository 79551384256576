import { useMainStore } from '~/stores'
import type { Pinia } from 'pinia'
export default defineNuxtPlugin(({ $pinia }) => {
  const pinia = $pinia as Pinia
  return {
    provide: {
      store: useMainStore(pinia)
    }
  }
})
