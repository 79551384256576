import { Model } from 'pinia-orm'
import Organization from '~/database/models/Organization'
import Agregate from '~/database/models/Agregate'
import Security from '~/database/models/Security'

export default class Valuation extends Model {
  static entity = 'valuations'

  static fields() {
    return {
      id: this.string(null).notNullable(),
      value: this.number(null),
      date: this.string(null).notNullable(),
      organization_id: this.string(null).notNullable(),
      agregate_id: this.string(null),
      security_id: this.string(null),
      organization: this.belongsTo(Organization, 'organization_id'),
      agregate: this.belongsTo(Agregate, 'agregate_id'),
      security: this.belongsTo(Security, 'security_id')
    }
  }

  declare id: string
  declare value: number
  declare date: string
  declare organization_id: string
  declare agregate_id: string | null
  declare security_id: string | null
  declare organization: Organization
  declare agregate: Agregate | null
  declare security: Security | null
}
