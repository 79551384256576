import { Model } from 'pinia-orm'
import Organization from '~/database/models/Organization'

export default class Admin extends Model {
  static entity = 'admins'

  static fields() {
    return {
      id: this.string(null).notNullable(),
      email: this.string(null).notNullable(),
      name: this.string(null).notNullable(),
      confirmed_at: this.string(null),
      invited_at: this.string(null),
      permission: this.string(null).notNullable(),
      organization_id: this.string(null).notNullable(),
      organization: this.belongsTo(Organization, 'organization_id')
    }
  }

  declare id: string
  declare email: string
  declare name: string
  declare confirmed_at: string | null
  declare invited_at: string | null
  declare permission: 'view' | 'edit' | 'admin'
  declare organization_id: string
  declare organization: Organization
}
