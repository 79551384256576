import { Model } from 'pinia-orm'
import Agregate from '~/database/models/Agregate'
import Entity from '~/database/models/Entity'
import Operation from '~/database/models/Operation'
import Security from '~/database/models/Security'
import Shareholder from '~/database/models/Shareholder'
import Transaction from '~/database/models/Transaction'
import Plan from '~/database/models/Plan'
import Valuation from '~/database/models/Valuation'
import Admin from '~/database/models/Admin'
import Waterfall from '~/database/models/Waterfall'

export default class Organization extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'organizations'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      /** @member { string } */
      id: this.string(null).notNullable(),
      name: this.string(null).notNullable(),
      description: this.string(null),
      date: this.string(null),
      currency: this.string(null),
      logo: this.string(null),
      landscape: this.string(null),
      structure: this.string(null),
      methodology: this.string(null),
      agregates: this.hasMany(Agregate, 'organization_id'),
      entities: this.hasMany(Entity, 'organization_id'),
      operations: this.hasMany(Operation, 'organization_id'),
      securities: this.hasMany(Security, 'organization_id'),
      shareholders: this.hasMany(Shareholder, 'organization_id'),
      transactions: this.hasMany(Transaction, 'organization_id'),
      valuations: this.hasMany(Valuation, 'organization_id'),
      plans: this.hasMany(Plan, 'organization_id'),
      admins: this.hasMany(Admin, 'organization_id'),
      waterfalls: this.hasMany(Waterfall, 'organization_id'),
      logoUrl: this.string(null),
      structureUrl: this.string(null)
    }
  }

  declare id: string
  declare name: string
  declare description: string | null
  declare date: string | null
  declare currency: string | null
  declare logo: string | null
  declare landscape: string | null
  declare structure: string | null
  declare methodology: string | null
  declare agregates: Agregate[]
  declare entities: Entity[]
  declare operations: Operation[]
  declare securities: Security[]
  declare shareholders: Shareholder[]
  declare transactions: Transaction[]
  declare valuations: Valuation[]
  declare plans: Plan[]
  declare admins: Admin[]
  declare waterfalls: Waterfall[]
  declare logoUrl: string | null
  declare structureUrl: string | null

  get valuationDates() {
    const valuationDates = this.valuations
      .filter(valuation => valuation.security_id)
      .map(valuation => valuation.date)
    const dates = [...new Set(valuationDates)]
    return dates.sort()
  }

  get lastValuationDate() {
    return this.valuationDates[this.valuationDates.length - 1]
  }

  get agregateDates() {
    const valuationDates = this.valuations
      .filter(valuation => valuation.agregate_id)
      .map(valuation => valuation.date)
    const dates = [...new Set(valuationDates)]
    return dates.sort()
  }

  get lastAgregateDate() {
    return this.agregateDates[this.agregateDates.length - 1]
  }

  get flows() {
    return this.shareholders
      .filter(shareholder => shareholder.type !== 'Holding')
      .reduce((flows, shareholder) => [...flows, ...shareholder.flows()], [] as Array<{value: number, date: string}>)
  }

  get cashIn() {
    return this.shareholders
      .filter(shareholder => shareholder.type !== 'Holding')
      .reduce((total, shareholder) => total + shareholder.cashIn(), 0)
  }

  get cashOut() {
    return this.shareholders
      .filter(shareholder => shareholder.type !== 'Holding')
      .reduce((total, shareholder) => total + shareholder.cashOut(), 0)
  }

  get unrealized() {
    return this.shareholders
      .filter(shareholder => shareholder.type !== 'Holding')
      .reduce((total, shareholder) => total + shareholder.unrealized(), 0)
  }

  get waterfall() {
    return this.waterfalls.find(waterfall => !waterfall.entity_id && !waterfall.security_id)
  }

  shareholder(id: string) {
    return this.shareholders.find(shareholder => shareholder.id === id)
  }
}
