export default defineNuxtPlugin(() => {
  const $toast = useToast()
  function info(title:string , description?: string) {
    $toast.add({
      title,
      description,
      icon: 'i-heroicons-information-circle',
      color: 'amber'
    })
  }
  function error(title:string , description?: string) {
    $toast.add({
      title,
      description,
      icon: 'i-heroicons-exclamation-circle',
      color: 'red'
    })
  }
  function success(title:string , description?: string) {
    $toast.add({
      title,
      description,
      icon: 'i-heroicons-check-circle',
      color: 'green'
    })
  }
  const toast = { info, error, success }

  return {
    provide: {
      toast
    }
  }
}
)
