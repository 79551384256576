import { defineStore } from 'pinia'
import { useMainStore } from '.'

export const useUser = defineStore('user', () => {
  const user = useSupabaseUser()
  const store = useMainStore()

  const permissions = computed(() => {
    return user.value?.app_metadata.permissions ?? {}
  })

  const isLoggedIn = computed(() => {
    return user.value !== null
  })

  const id = computed(() => {
    return user.value?.id
  })

  const name = computed(() => {
    return user.value?.user_metadata.name
  })

  const email = computed(() => {
    return user.value?.email
  })

  const availablePlans = computed(() => {
    const organizationIds = []
    for (const permission in permissions.value) {
      if (permission !== 'app') {
        organizationIds.push(permission)
      }
    }
    return organizationIds
  })

  const hasAppAccess = computed(() => {
    return !!permissions.value.app
  })

  const hasManyPlans = computed(() => {
    return hasAppAccess.value || availablePlans.value.length > 1
  })

  const canAdminApp = computed(() => {
    return permissions.value.app === 'admin'
  })

  const canEditApp = computed(() => {
    return permissions.value.app === 'edit' || canAdminApp.value
  })

  const canViewApp = computed(() => {
    return permissions.value.app === 'view' || canEditApp.value
  })

  const hasPlanAccess = computed(() => {
    return hasAppAccess.value || availablePlans.value.includes(store.organizationId!)
  })

  const canAdmin = computed(() => {
    return canAdminApp.value || (permissions.value[store.organizationId!] === 'admin')
  })

  const canEdit = computed(() => {
    return canEditApp.value || canAdmin.value || (permissions.value[store.organizationId!] === 'edit')
  })

  const canView = computed(() => {
    return canViewApp.value || canEdit.value || (permissions.value[store.organizationId!] === 'view')
  })

  const role = computed(() => {
    let role = 'Beneficiary'
    if (hasAppAccess.value) {
      role = 'App '
    } else if (hasPlanAccess.value) {
      role = 'Plan '
    }
    if (canAdmin.value) {
      role += 'Admin'
    } else if (canEdit.value) {
      role += 'Editor'
    } else if (canView.value) {
      role += 'Viewer'
    } else {
      role += 'Unknown'
    }
    return role
  })

  return { permissions, isLoggedIn, id, name, email, availablePlans, hasAppAccess, hasManyPlans, canAdminApp, canEditApp, canViewApp, hasPlanAccess, canAdmin, canEdit, canView, role }
})
