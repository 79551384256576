import { default as activateuiKXwISJIJMeta } from "/opt/build/repo/pages/activate.vue?macro=true";
import { default as dashboardsnv2SXGj98dMeta } from "/opt/build/repo/pages/dashboards.vue?macro=true";
import { default as documentsNrvufYDaNdMeta } from "/opt/build/repo/pages/documents.vue?macro=true";
import { default as forgotUwPgolUkcoMeta } from "/opt/build/repo/pages/forgot.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as logoutlLSqGnk5PzMeta } from "/opt/build/repo/pages/logout.vue?macro=true";
import { default as organizationslkC4K1Hx64Meta } from "/opt/build/repo/pages/organizations.vue?macro=true";
import { default as performance83nFuEvU2wMeta } from "/opt/build/repo/pages/performance.vue?macro=true";
import { default as plansPed13sMCSsMeta } from "/opt/build/repo/pages/plans.vue?macro=true";
import { default as resetBBQxqLgtvHMeta } from "/opt/build/repo/pages/reset.vue?macro=true";
import { default as adminsi605p1QTHtMeta } from "/opt/build/repo/pages/settings/admins.vue?macro=true";
import { default as agregatesjD6ILlQkD4Meta } from "/opt/build/repo/pages/settings/agregates.vue?macro=true";
import { default as entitiesSwq3Y2uRTHMeta } from "/opt/build/repo/pages/settings/entities.vue?macro=true";
import { default as indexoWr0YesFbSMeta } from "/opt/build/repo/pages/settings/index.vue?macro=true";
import { default as operationsFKt8RjrYUFMeta } from "/opt/build/repo/pages/settings/operations.vue?macro=true";
import { default as organizationMhttk0AZobMeta } from "/opt/build/repo/pages/settings/organization.vue?macro=true";
import { default as planseegoKhXhS9Meta } from "/opt/build/repo/pages/settings/plans.vue?macro=true";
import { default as securitiest2ui5daCX0Meta } from "/opt/build/repo/pages/settings/securities.vue?macro=true";
import { default as shareholdersyrcVdnSPG7Meta } from "/opt/build/repo/pages/settings/shareholders.vue?macro=true";
import { default as simulationsRwbsiTXOLIMeta } from "/opt/build/repo/pages/settings/simulations.vue?macro=true";
import { default as transactionsY4y4SW13XaMeta } from "/opt/build/repo/pages/settings/transactions.vue?macro=true";
import { default as valuationsy33CC1tcAhMeta } from "/opt/build/repo/pages/settings/valuations.vue?macro=true";
import { default as settings6ZNFu1CSghMeta } from "/opt/build/repo/pages/settings.vue?macro=true";
import { default as simulationsFLngEN3bVEMeta } from "/opt/build/repo/pages/simulations.vue?macro=true";
import { default as tablesybEr28xRjoMeta } from "/opt/build/repo/pages/tables.vue?macro=true";
import { default as valuationstjhBKLnsdhMeta } from "/opt/build/repo/pages/valuations.vue?macro=true";
export default [
  {
    name: "activate",
    path: "/activate",
    meta: activateuiKXwISJIJMeta || {},
    component: () => import("/opt/build/repo/pages/activate.vue").then(m => m.default || m)
  },
  {
    name: "dashboards",
    path: "/dashboards",
    meta: dashboardsnv2SXGj98dMeta || {},
    component: () => import("/opt/build/repo/pages/dashboards.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    meta: documentsNrvufYDaNdMeta || {},
    component: () => import("/opt/build/repo/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotUwPgolUkcoMeta || {},
    component: () => import("/opt/build/repo/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginBXD9sq3T3sMeta || {},
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutlLSqGnk5PzMeta || {},
    component: () => import("/opt/build/repo/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "organizations",
    path: "/organizations",
    component: () => import("/opt/build/repo/pages/organizations.vue").then(m => m.default || m)
  },
  {
    name: "performance",
    path: "/performance",
    meta: performance83nFuEvU2wMeta || {},
    component: () => import("/opt/build/repo/pages/performance.vue").then(m => m.default || m)
  },
  {
    name: "plans",
    path: "/plans",
    meta: plansPed13sMCSsMeta || {},
    component: () => import("/opt/build/repo/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: "reset",
    path: "/reset",
    meta: resetBBQxqLgtvHMeta || {},
    component: () => import("/opt/build/repo/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: settings6ZNFu1CSghMeta?.name,
    path: "/settings",
    meta: settings6ZNFu1CSghMeta || {},
    component: () => import("/opt/build/repo/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-admins",
    path: "admins",
    component: () => import("/opt/build/repo/pages/settings/admins.vue").then(m => m.default || m)
  },
  {
    name: "settings-agregates",
    path: "agregates",
    component: () => import("/opt/build/repo/pages/settings/agregates.vue").then(m => m.default || m)
  },
  {
    name: "settings-entities",
    path: "entities",
    component: () => import("/opt/build/repo/pages/settings/entities.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    meta: indexoWr0YesFbSMeta || {},
    component: () => import("/opt/build/repo/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-operations",
    path: "operations",
    component: () => import("/opt/build/repo/pages/settings/operations.vue").then(m => m.default || m)
  },
  {
    name: "settings-organization",
    path: "organization",
    component: () => import("/opt/build/repo/pages/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: "settings-plans",
    path: "plans",
    component: () => import("/opt/build/repo/pages/settings/plans.vue").then(m => m.default || m)
  },
  {
    name: "settings-securities",
    path: "securities",
    component: () => import("/opt/build/repo/pages/settings/securities.vue").then(m => m.default || m)
  },
  {
    name: "settings-shareholders",
    path: "shareholders",
    component: () => import("/opt/build/repo/pages/settings/shareholders.vue").then(m => m.default || m)
  },
  {
    name: "settings-simulations",
    path: "simulations",
    component: () => import("/opt/build/repo/pages/settings/simulations.vue").then(m => m.default || m)
  },
  {
    name: "settings-transactions",
    path: "transactions",
    component: () => import("/opt/build/repo/pages/settings/transactions.vue").then(m => m.default || m)
  },
  {
    name: "settings-valuations",
    path: "valuations",
    component: () => import("/opt/build/repo/pages/settings/valuations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "simulations",
    path: "/simulations",
    meta: simulationsFLngEN3bVEMeta || {},
    component: () => import("/opt/build/repo/pages/simulations.vue").then(m => m.default || m)
  },
  {
    name: "tables",
    path: "/tables",
    meta: tablesybEr28xRjoMeta || {},
    component: () => import("/opt/build/repo/pages/tables.vue").then(m => m.default || m)
  },
  {
    name: "valuations",
    path: "/valuations",
    meta: valuationstjhBKLnsdhMeta || {},
    component: () => import("/opt/build/repo/pages/valuations.vue").then(m => m.default || m)
  }
]