import { Model } from 'pinia-orm'
import Organization from '~/database/models/Organization'
import Entity from '~/database/models/Entity'
import type Security from '~/database/models/Security'
import Transaction from '~/database/models/Transaction'

export default class Shareholder extends Model {
  static entity = 'shareholders'

  static fields() {
    return {
      id: this.string(null).notNullable(),
      name: this.string(null).notNullable(),
      type: this.string(null).notNullable(),
      category: this.string(null),
      position: this.string(null),
      organization_id: this.string(null).notNullable(),
      self_entity_id: this.string(null),
      organization: this.belongsTo(Organization, 'organization_id'),
      self_entity: this.belongsTo(Entity, 'self_entity_id'),
      transactions: this.hasMany(Transaction, 'shareholder_id')
    }
  }

  declare id: string
  declare name: string
  declare type: string
  declare category: string | null
  declare position: string | null
  declare organization_id: string
  declare self_entity_id: string | null
  declare organization: Organization
  declare self_entity: Entity | null
  declare transactions: Transaction[]

  get distinctDates() {
    const distinctDates = this.transactions
      .filter((transaction, index, transactions) => transactions.findIndex(tr => tr.date === transaction.date) === index)
      .map(transaction => transaction.date)
      .sort()
    return distinctDates
  }

  get operations() {
    const groups = this.distinctDates.map(date => {
      const transactions = this.transactions.filter(transaction => transaction.date === date)
      return { date, transactions }
    })
    return groups
  }

  get fullName() {
    if (this.type !== 'Holding') {
      return this.name
    } else {
      return this.self_entity?.name
    }
  }

  get transactionsBySecurity() {
    return this.organization.securities.map(security => {
      const transactions = this.transactions.filter(transaction => transaction.security_id === security.id)
      return { security, transactions }
    })
  }

  flows(beforeDate = new Date().toISOString().slice(0, 10)) {
    return this.transactions
      .filter(transaction => transaction.date <= beforeDate)
      .map(transaction => {
        return { value: -transaction.value, date: transaction.date }
      })
  }

  cashIn(beforeDate = new Date().toISOString().slice(0, 10)) {
    return this.flows(beforeDate)
      .filter(flow => flow.value < 0)
      .reduce((total, flow) => total + flow.value, 0)
  }

  cashOut(beforeDate = new Date().toISOString().slice(0, 10)) {
    return this.flows(beforeDate)
      .filter(flow => flow.value > 0)
      .reduce((total, flow) => total + flow.value, 0)
  }

  unrealized(beforeDate = new Date().toISOString().slice(0, 10), security?: Security) {
    return this.transactions
      .filter(transaction => transaction.date <= beforeDate)
      .filter(transaction => security === undefined || security.id === transaction.security_id)
      .reduce((total, transaction) => total + transaction.quantity * transaction.security?.price(beforeDate), 0)
  }
  /* shareholder.exposure = function(beforeDate = new Date(), security = undefined) {
    return sharetransactionsions
      .filter(transaction => transaction.operation.date <= beforeDate)
      .filter(transaction => security === undefined || security.id === transaction.securityId)
      .reduce((total, transaction) => total + transaction.value, 0)
  } */

  balances(onDate = new Date().toISOString().slice(0, 10)) {
    return this.transactionsBySecurity.map(({ security, transactions }) => {
      const selected = transactions.filter(transaction => transaction.date <= onDate)
      const fullyDiluted = selected.reduce((total, transaction) => total + transaction.quantity, 0)
      const outstanding = selected
        .filter(transaction => transaction.type !== 'grant' && transaction.type !== 'vesting')
        .reduce((total, transaction) => total + transaction.quantity, 0)
      const unissued = fullyDiluted - outstanding
      return { security, fullyDiluted, outstanding, unissued }
    })
  }

  balance(onDate = new Date().toISOString().slice(0, 10), security: Security) {
    return this.balances(onDate).find(position => position.security.id === security.id)
  }

  outstanding(onDate = new Date().toISOString().slice(0, 10), security: Security) {
    return this.balance(onDate, security)?.outstanding
  }

  fullyDiluted(onDate = new Date().toISOString().slice(0, 10), security: Security) {
    return this.balance(onDate, security)?.fullyDiluted
  }

  unissued(onDate = new Date().toISOString().slice(0, 10), security: Security) {
    return this.balance(onDate, security)?.unissued
  }

  movements(onDate: string) {
    return this.transactionsBySecurity.map(({ security, transactions }) => {
      const selected = transactions.filter(transaction => transaction.date === onDate)
      const fullyDiluted = selected.reduce((total, transaction) => total + transaction.quantity, 0)
      const outstanding = selected
        .filter(transaction => transaction.type !== 'grant' && transaction.type !== 'vesting')
        .reduce((total, transaction) => total + transaction.quantity, 0)
      const unissued = fullyDiluted - outstanding
      return { security, fullyDiluted, outstanding, unissued }
    })
  }

  movementsByDate(beforeDate: string) {
    return this.distinctDates
      .filter(date => date <= beforeDate)
      .map(date => {
        const movements = this.movements(date)
        return { date, movements }
      })
  }

  movement(onDate: string, security: Security) {
    return this.transactions
      .filter(transaction => (transaction.date === onDate) && (transaction.security_id === security.id))
      .reduce((total, transaction) => total + transaction.quantity, 0)
  }

  get grantEvents() {
    return this.transactions
      .filter(({ type, quantity, plan_id }) => type === 'grant' && quantity > 0 && plan_id)
      .map(transaction => ({ ...transaction, event: 'grant' }))
  }

  get exerciseEvents() {
    return this.transactions
      .filter(({ type, counterpart_id, plan_id }) => type === 'vesting' && counterpart_id && plan_id)
      .map(transaction => ({ ...transaction, event: 'exercise' }))
  }

  get forfeitEvents() {
    return this.transactions
      .filter(({ type, quantity, plan_id }) => type === 'grant' && quantity < 0 && plan_id)
      .map(transaction => ({ ...transaction, event: 'forfeit' }))
  }

  get issuanceEvents() {
    return this.transactions
      .filter(({ type, plan_id }) => type === 'issuance' && plan_id)
      .map(transaction => ({ ...transaction, event: 'issuance' }))
  }

  get saleEvents() {
    return this.transactions
      .filter(({ type, plan_id }) => type === 'transfer' && plan_id)
      .map(transaction => ({ ...transaction, event: 'sale' }))
  }

  get plansEvents() {
    return [
      ...this.grantEvents,
      ...this.exerciseEvents,
      ...this.issuanceEvents,
      ...this.forfeitEvents,
      ...this.saleEvents
    ]
  }
}
