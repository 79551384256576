<template>
  <NuxtLayout name="centered">
    <h1 class="text-2xl font-normal text-secondary">
      Ooops... Something went wrong
    </h1>
    <h2 class="text-sm font-light text-gray-800">
      An error has occured.
    </h2>
    <details class="mt-6">
      <summary class="flex text-body-2 font-weight-light list-none [&::-webkit-details-marker]:hidden">
        <span class="bg-red-500 text-white text-xs font-medium mr-2 px-2.5 py-2 rounded-full">
          {{ error.statusCode }}
        </span>
        <code class="grow bg-gray-100 text-gray-700 text-xs px-2 py-2">
          {{ error.message }}
        </code>
      </summary>

      <MdPreview
        v-model="errorDetails"
        language="en-US"
        preview-theme="github"
        code-theme="stackoverflow"
        class="[&_pre]:whitespace-pre-wrap [&_pre]:!text-xs"
      />

      <p class="mt-2 text-sm font-thin">
        This incident has been registered automatically in our systems with id {{ eventId }}.
        You don't need to take further action.
      </p>
      <div class="flex mt-2 p-2 rounded-sm text-primary-600 hover:text-secondary hover:bg-gray-100 cursor-pointer">
        <UIcon
          name="i-mingcute-bug-line"
          class="w-5 h-5 mr-4"
        />
        <div class="flex flex-col">
          <p
            class="text-sm mb-2"
            @click="report"
          >
            Fill bug report
          </p>
          <p class="text-xs text-gray-700">
            You can help our engineers fix the issue by providing us details on how it happened.
          </p>
        </div>
      </div>
    </details>
    <br>
    <button @click="back">
      Back to Home page
    </button>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useUser } from './stores/user';
import { MdPreview } from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'
import '~/assets/common/md.css'
const props = defineProps({
  error: {
    type: Object,
    default: null
  }
})

useHead({
  title: props.error.statusCode === 404 ? 'Page Not Found' : 'Application Error'
})

const eventId = computed(() => {
  const { $sentry } = useNuxtApp()
  return $sentry.captureException(props.error)
})

const errorDetails = computed(() => {
  return '```json\n' + JSON.stringify(props.error, undefined, 2).trim() + '\n```'
})

function report() {
  const { $sentry } = useNuxtApp()
  const user = useUser()
  $sentry.showReportDialog({ eventId: eventId.value, user, lang: 'en' })
}

async function back() {
  await clearError({ redirect: '/' })
}
</script>
