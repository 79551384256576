import { useScript } from 'unhead'

interface Turnstile {
  execute: (element: string, options?: { 
    sitekey?: string, 
    appearance?: 'always' | 'execute' | 'interaction-only', 
    execution?: 'render' | 'execute',
    language?: string,
    'response-field'?: boolean, 
    callback: (response: string) => void,
    'timeout-callback'?: (data: any) => void,
    'expired-callback'?: (data: any) => void, 
    'error-callback'?: (err: any) => boolean, 
    'before-interactive-callback'?: () => void, 
    'after-interactive-callback'?: () => void 
  }) => Promise<void>,
  render: (element: string | HTMLElement, options?: { 
    sitekey: string, 
    appearance?: 'always' | 'execute' | 'interaction-only', 
    execution?: 'render' | 'execute',
    language?: string,
    'response-field'?: boolean, 
    callback?: (token: string) => void,
    'timeout-callback'?: () => void,
    'expired-callback'?: () => void, 
    'error-callback'?: (err: any) => boolean, 
    'before-interactive-callback'?: () => void, 
    'after-interactive-callback'?: () => void 
  }) => Promise<string>
  remove: (widgetId?: string) => Promise<void>,
  reset: (widgetId?: string) => Promise<void>
}

export default defineNuxtPlugin(() => {
  console.log('Turnstile plugin loading')
  const { render, remove, reset, execute } = useScript<Turnstile>({
    src: 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit',
    async: true,
    defer: true,
    fetchpriority: 'high',
  }, {
    use: () => {
      //@ts-expect-error
      return window.turnstile
    },
    
  })
  console.log('Turnstile plugin loaded')

  return {
    provide: {
      turnstile: { render, remove, reset, execute }
    }
  }
}
)
