// import MarkdownIt from '@nuxtjs/markdownit'

const filters = {
  formatDate(value?: string | number | Date) {
    if (!value) {
      return 'n.a.'
    }
    return new Date(value).toLocaleString('en', {
      month: 'short',
      year: 'numeric'
    })
  },
  formatLongDate(value?: string | number | Date) {
    if (!value) {
      return 'n.a.'
    }
    return new Date(value).toLocaleString('en', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    })
  },
  formatYear(value?: string | number | Date) {
    if (!value) {
      return 'n.a.'
    }
    return new Date(value).toLocaleString('en', {
      year: 'numeric'
    })
  },
  formatDay(value?: string | number | Date) {
    if (!value) {
      return 'n.a.'
    }
    return new Date(value).toLocaleString('en', {
      month: 'long',
      day: 'numeric'
    })
  },
  formatDateTime(value?: string | number | Date) {
    if (!value) {
      return 'n.a.'
    }
    return new Date(value).toLocaleString('en', {
      dateStyle: 'medium',
      timeStyle: 'medium'
    })
  },
  formatAmount(value?: string | number) {
    if (typeof value !== 'number') {
      return value
    }
    let compact = Math.abs(value)
    let unit = ''
    if (compact >= 1e9) {
      compact = Math.round(compact / 0.1e9) / 10
      unit = 'bn'
    } else if (compact >= 1e6) {
      compact = Math.round(compact / 0.1e6) / 10
      unit = 'm'
    } else if (compact >= 1e3) {
      compact = Math.round(compact / 0.1e3) / 10
      unit = 'k'
    }
    let result = compact.toLocaleString('en', {
      style: 'currency',
      currency: 'EUR',
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 3
    }) + unit
    if (value < 0) {
      result = '(' + result + ')'
    }
    if (value === 0) {
      result = '-'
    }
    return result
  },
  formatAccounting(value?: string | number, digits = 0) {
    if (typeof value !== 'number') {
      return value
    }
    let result = Math.abs(value).toLocaleString('en', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    })
    if (value < 0) {
      result = '(' + result + ')'
    }
    return result
  },
  formatPercent(value: string | number) {
    if (typeof value !== 'number') {
      return value
    }
    return value.toLocaleString('en', {
      style: 'percent',
      minimumFractionDigits: 1
    })
  },
  formatMultiple(value: string | number, digits = 2) {
    if (typeof value !== 'number') {
      return value
    }
    return value.toLocaleString('en', {
      style: 'decimal',
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    }) + 'x'
  },
  formatDecimal(value: string | number, digits = 2) {
    if (typeof value !== 'number') {
      return value
    }
    const abs = Math.abs(value).toLocaleString('en', {
      style: 'decimal',
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    })
    if (value < 0) {
      return '(' + abs + ')'
    }
    if (value === 0) {
      return '-'
    }
    return abs
  },
  formatInteger(value: string | number) {
    if (typeof value !== 'number') {
      return value
    }
    return filters.formatDecimal(value, 0)
  },
  formatHtml(_value: any) {
    return ''
    // return MarkdownIt.render(value)
  },
  format(value: any, ...args: any[]) {
    const type = args.shift()
    switch (type) {
      case 'date': return filters.formatDate(value)
      case 'longdate': return filters.formatLongDate(value)
      case 'year': return filters.formatYear(value)
      case 'day': return filters.formatDay(value)
      case 'datetime': return filters.formatDateTime(value)
      case 'amount': return filters.formatAmount(value)
      case 'accounting': return filters.formatAccounting(value, ...args)
      case 'percent': return filters.formatPercent(value)
      case 'multiple': return filters.formatMultiple(value, ...args)
      case 'decimal': return filters.formatDecimal(value, ...args)
      case 'integer': return filters.formatInteger(value)
      case 'accounting2': return filters.formatAccounting(value, 2)
      default: return value
    }
  }
}

export default filters
