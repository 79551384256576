import { Model } from 'pinia-orm'
import Organization from '~/database/models/Organization'
import Valuation from '~/database/models/Valuation'

export default class Agregate extends Model {
  static entity = 'agregates'

  static fields() {
    return {
      id: this.string(null).notNullable(),
      name: this.string(null).notNullable(),
      organization_id: this.string(null).notNullable(),
      organization: this.belongsTo(Organization, 'organization_id'),
      valuations: this.hasMany(Valuation, 'agregate_id')
    }
  }

  declare id: string
  declare name: string
  declare organization_id: string
  declare organization: Organization
  declare valuations: Valuation[]

  get valuationDates() {
    const valuationDates = this.valuations
      .map(valuation => valuation.date)
    const dates = [...new Set(valuationDates)]
    return dates.sort()
  }

  get value() {
    return this.lastValue()
  }

  get valuationDate() {
    return this.lastValuationDate()
  }

  lastValuationDate(beforeDate = new Date().toISOString().slice(0, 10)) {
    return [...this.valuationDates].reverse().find(date => date <= beforeDate)
  }

  lastValue(beforeDate = new Date().toISOString().slice(0, 10)) {
    const valuationDate = this.lastValuationDate(beforeDate)
    const valuation = this.valuations.find(valuation => valuation.date === valuationDate)
    return valuation?.value
  }
}
