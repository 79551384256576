import { Model, useRepo } from 'pinia-orm'
import Organization from '~/database/models/Organization'
import Operation from '~/database/models/Operation'
import Shareholder from '~/database/models/Shareholder'
import Security from '~/database/models/Security'
import Plan from '~/database/models/Plan'
import filters from '~/utils/filters'

export default class Transaction extends Model {
  static entity = 'transactions'

  static fields() {
    return {
      id: this.string(null).notNullable(),
      quantity: this.number(null).notNullable(),
      unit_price: this.number(null),
      value: this.number(null),
      type: this.string(null).notNullable(),
      date: this.string(null).notNullable(),
      organization_id: this.string(null).notNullable(),
      operation_id: this.string(null),
      shareholder_id: this.string(null).notNullable(),
      security_id: this.string(null).notNullable(),
      counterpart_id: this.string(null),
      plan_id: this.string(null),
      is_reserve: this.boolean(false),
      organization: this.belongsTo(Organization, 'organization_id'),
      operation: this.belongsTo(Operation, 'operation_id'),
      shareholder: this.belongsTo(Shareholder, 'shareholder_id'),
      security: this.belongsTo(Security, 'security_id'),
      plan: this.belongsTo(Plan, 'plan_id'),
      counterpart: this.belongsTo(Transaction, 'counterpart_id'),
      counterpart_of: this.hasOne(Transaction, 'counterpart_id')
    }
  }

  declare id: string
  declare quantity: number
  declare unit_price: number
  declare value: number
  declare type: 'issuance' | 'transfer' | 'dividend' | 'grant' | 'vesting' | 'conversion' | 'authorization'
  declare date: string
  declare organization_id: string
  declare operation_id: string | null
  declare shareholder_id: string
  declare security_id: string
  declare counterpart_id: string | null
  declare plan_id: string | null
  declare is_reserve: boolean
  declare organization: Organization
  declare operation: Operation | null
  declare shareholder: Shareholder
  declare security: Security
  declare plan: Plan | null
  // eslint-disable-next-line no-use-before-define
  declare counterpart: Transaction | null
  // eslint-disable-next-line no-use-before-define
  declare counterpart_of: Transaction | null

  get invested() {
    /*
    switch (this.type) {
      case 'issuance':
        if (this.quantity > 0) {
          return -this.value
        } else {
          return 0
        }
      case 'transfer':
      case 'dividend':
        if (this.value > 0) {
          return -this.value
        } else {
          return 0
        }
    } */
    const invested = this.value > 0 ? -this.value : 0
    return invested
  }

  get realized() {
    const realized = this.value < 0 ? -this.value : 0
    return realized
  }

  get issued() {
    const issued = this.type !== 'grant' && this.type !== 'vesting' ? this.quantity : 0
    return issued
  }

  get label() {
    const quantity = filters.formatInteger(Math.abs(this.quantity))
    const securityName = this.security.fullName
    const counterpartName = this.counterpart ? useRepo(Shareholder).query().with('self_entity').find(this.counterpart.shareholder_id)?.fullName : ''
    let label
    switch (this.type) {
      case 'issuance':
        if (this.quantity < 0) {
          label = `Redemption of ${quantity} ${securityName} shares`
        } else {
          label = `Subscription to ${quantity} ${securityName} shares`
        }
        break
      case 'transfer':
        if (this.quantity < 0) {
          label = `Sale of ${quantity} ${securityName} shares to ${counterpartName}`
        } else {
          label = `Purchase of ${quantity} ${securityName} shares from ${counterpartName}`
        }
        break
      case 'dividend':
        label = `Dividend on ${quantity} ${securityName} shares`
        break
      case 'conversion':
        label = 'Conversion'
        break
      case 'grant':
        if (this.quantity < 0) {
          label = `${this.plan?.name}: Forfeit of ${quantity} ${securityName} shares`
        } else {
          label = `${this.plan?.name}: Grant of ${quantity} ${securityName} shares`
        }
        break
      case 'vesting':
        label = `${this.plan?.name}: Vesting of ${quantity} ${securityName} shares`
        break
      default:
        break
    }

    // Override label in case of vesting counterpart
    if (this.counterpart_of?.type === 'vesting') {
      label = `${this.counterpart_of?.plan?.name}: Exercise of ${quantity} ${securityName} shares`
    }
    return label
  }
}
