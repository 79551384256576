import { Model } from 'pinia-orm'
import Organization from '~/database/models/Organization'
import Security from '~/database/models/Security'
import Waterfall from '~/database/models/Waterfall'

export default class Entity extends Model {
  static entity = 'entities'

  static fields() {
    return {
      id: this.string(null).notNullable(),
      name: this.string(null).notNullable(),
      legal_name: this.string(null),
      organization_id: this.string(null).notNullable(),
      organization: this.belongsTo(Organization, 'organization_id'),
      securities: this.hasMany(Security, 'entity_id'),
      waterfalls: this.hasMany(Waterfall, 'entity_id')
    }
  }

  declare id: string
  declare name: string
  declare legal_name: string | null
  declare organization_id: string
  declare organization: Organization
  declare securities: Security[]
  declare waterfalls: Waterfall[]

  get waterfall() {
    return this.waterfalls.find(waterfall => !waterfall.security_id)
  }
}
